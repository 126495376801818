/// Color
$bleu: #007582;
$gris: #1d1d1d;
$vert: #A1D884;
$rouge: #e86776;

/// Background
$backgroundBleu: $bleu;
$backgroundBleuHover: $vert;
$backgroundBleuFade: #b2d5d9;
$backgroundVert: $vert;

/// Link Color
$linkBleu: $bleu;
$linkBleuHover: $vert;