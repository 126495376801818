// Helpers
@import "helpers/reset";
@import "helpers/mixins";
@import "helpers/variables";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600&display=swap');

html, body {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 0;
  color: #fff;
  position: relative;
}

body {
  width: 100%;
  height: 100vh;

  a {
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  .none {
    display: none;
  }
}

strong {
  font-weight: 600;
}

h2 {
  font-size: 25px;
  line-height: 28px;
}

.btn {
  padding: 8px;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  outline: none;
  @include transition(all .2s ease);
  &.btn-bleu {
    background: $backgroundBleu;
    border: 1px solid $backgroundBleu;
    text-align: center;
    color: #fff;
    &:hover {
      background: $backgroundBleuHover;
      border: 1px solid $backgroundBleuHover;
    }
  }
  &.btn-contour-bleu {
    color: $bleu;
    background: #fff;
    border: 1px solid $backgroundBleu;
    &:hover {
      background: $backgroundBleuFade;
      border: 1px solid $backgroundBleuFade;
      color: #fff;
    }
  }
  &.btn-load {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      background: $backgroundBleu;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      background-image: url('../images/loading.gif');
      background-repeat: no-repeat;
      display: block;
      background-size: 60px;
      background-position: center;
      position: absolute;
      top: 0;
      left: 0;
    }
    &:hover {
      background: $backgroundBleu;
    }
  }
  &.btn-carre-bleu {
    background: $backgroundBleu;
    text-align: center;
    color: #fff;
    text-decoration: none;
    &:hover {
      background: $backgroundBleuHover;
      color: #fff;
    }
  }
}

a {
  color: $linkBleu;
  text-decoration: none;
  @include transition(all .2s ease);
  &:hover {
    color: $linkBleuHover;
    text-decoration: underline;
  }
}

/* WEBP */
.webp {
  .pageLayout {
    /* Home */
    &-home {
      .pageLayout__content {
        &__media {
          &:before {
            background: url("../images/bg-bionalysx500.webp");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }
    /* End Home */

    /* Consent */
    &-consent {
      .pageLayout__content {
        &__bg {
          &:before {
            content: '';
            background: url("../images/consentementx500.webp");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom center;
          }
        }
      }
    }
    /* End Consent */

    /* Bilan */
    &-bilan {
      .pageLayout__content {
        &__bg {
          &:before {
            content: '';
            background: url("../images/bilanx500.webp");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom center;
          }
        }
      }
    }
    /* End Bilan */
  }
}
/* END WEBP */

/* NO WEBP */
.no-webp,
.no-js {
  .pageLayout {
    /* Home */
    &-home {
      .pageLayout__content {
        &__media {
          &:before {
            background: url("../images/bg-bionalysx500.jpg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center center;
          }
        }
      }
    }
    /* End Home */

    /* Consent */
    &-consent {
      .pageLayout__content {
        &__bg {
          &:before {
            content: '';
            background: url("../images/bilanx500.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom center;
          }
        }
      }
    }
    /* End Consent */

    /* Bilan */
    &-bilan {
      .pageLayout__content {
        &__bg {
          &:before {
            content: '';
            background: url("../images/consentementx500.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom center;
          }
        }
      }
    }
    /* End Bilan */
  }
}
/* END NO WEBP */

/* Desktop */
.isDesktop {
  select {
    display: none;
  }

  .pageLayout {
    &-form {
      .pageLayout__content {
        &__stepform__content__fields {
          .field {
            .content-field-radio {
              margin: 30px 0 10px;
              label {
                color: $bleu;
                text-align: center;
                font-size: 15px;
                line-height: 18px;
                @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, 0.1));
                background-color: #fff;
                padding: 10px 15px 15px;
                box-sizing: border-box;
                margin: 15px 0;
                font-weight: 500;
                display: inline-block;
                width:100%;
                @include transition(all .2s ease);
                &.active, &:hover {
                  background-color: $backgroundBleu;
                  color: #fff;
                }
                input {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
/* END Desktop */

.pageLayout {
  width: 100%;
  box-sizing: border-box;

  &__content {
    &-middle {
      padding: 20px 0;
    }
    &__container {
      padding: 0 20px;
      position: relative;
      z-index: 1;
    }

    &__head {
      max-width: 100%;
      margin: 50px 0 40px;
      img {
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        display: block;
      }
    }

    .message-top {
      font-size: 14px;
      padding: 7px 10px;
      margin: 30px 0 15px;
      &-error {
        background: rgb(251, 187, 199);
        color: #000;
      }
      &-success {
        background: #9cc26f;;
        color: #fff;
      }
    }
  }

  /* Profile */
  /* End Profile */

  /* Home */
  &-home {
    .pageLayout__content {
      padding: 0;
      &__head {
        margin: 0;
        &:after {
          content: '';
          border-bottom: 1px solid $bleu;
          width: calc(100% - 40px);
          height: 100%;
          display: block;
          z-index: 2;
          margin: 0 auto;
        }
      }
      &__media {
        height: 205px;
        position: relative;
        padding: 0 20px;
        z-index: 1;
        &:before {
          content: '';
          position: absolute;
          height: calc(100% - 40px);
          display: block;
          width: 100%;
          right: 0;
          top: 50%;
          @include transform(translateY(-50%));
          z-index: -1;
        }
        &:after {
          content: '';
          border: 1px solid #007582;
          width: 100%;
          height: 100%;
          display: block;
          z-index: 2;
        }
      }
      &__account {
        &__container {
          padding: 10px;
        }
        h2 {
          font-size: 20px;
          color: $bleu;
          font-weight: 500;
          padding: 0 30px;
          margin-top: 20px;
          text-align: center;
        }
        &__content {
          margin-top: 30px;
          padding: 0 30px;
          &__praticien {
            color: $bleu;
            font-weight: 500;
            font-size: 18px;
            text-align: center;
          }
          &__accounts {
            text-align: center;
            margin: 20px 0 20px;
            p {
              color: $gris;
              font-size: 16px;
              margin-bottom: 10px;
              font-weight: 400;
            }
            a {
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              width: 100%;
              display: block;
              font-size: 16px;
              &:hover {
              }
            }
            &__already {

            }
            &__create {
              margin-top: 20px;
            }
          }
        }
      }

      &__process {
        margin-bottom: 3px;
        &:after {
          content: '';
          border-bottom: 1px solid #d9d9d9;
          width: 100%;
          height: 100%;
          display: block;
          z-index: 2;
          margin: 28px auto 0;
          max-width: 260px;
          width: 100%;
        }
        &__item {
          display: block;
          max-width: 260px;
          width: 100%;
          background-color: #ffffff;
          @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, 0.1));
          margin: 30px auto 0;
          padding: 30px 10px 20px 25px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          min-height: 270px;
          &:first-child {
            margin-top: 40px;
          }
          &-1 {
            .pageLayout__content__process__item__step {
              p {
                &:after {
                  content: '';
                  background: url(../images/icon-analyse-profil.svg) top center no-repeat;
                  background-size: contain;
                }
              }
            }
          }
          &-2 {
            .pageLayout__content__process__item__step {
              p {
                &:after {
                  content: '';
                  background: url(../images/icon-resultats.svg) top center no-repeat;
                  background-size: contain;
                }
              }
            }
          }
          &-3 {
            .pageLayout__content__process__item__step {
              p {
                &:after {
                  content: '';
                  background: url(../images/icon-causes.svg) top center no-repeat;
                  background-size: contain;
                }
              }
            }
          }
          &-4 {
            .pageLayout__content__process__item__step {
              p {
                &:after {
                  content: '';
                  background: url(../images/icon-identification.svg) top center no-repeat;
                  background-size: contain;
                }
              }
            }
          }
          &__step {
            font-weight: 500;
            color: $bleu;
            font-size: 60px;
            p {
              display: flex;
              align-items: center;
              &:after {
                content: '';
                display: block;
                width: 48px;
                height: 48px;
                margin-left: 15px;
              }
            }
          }
          &__description {
            margin-top: 20px;
            font-size: 14px;
            color: $gris;
            line-height: 20px;
          }
        }
      }
      &__customer {
        padding: 30px 0;
        &__title {
          text-align: center;
          color: $gris;
          font-size: 19px;
          line-height: 24px;
        }
        &__description {
          font-size: 14px;
          line-height: 20px;
          color: $gris;
          font-weight: 500;
          margin-top: 30px;
          span {
            color: $bleu;
          }
        }
        &__copmed {
          z-index: 2;
          position: relative;
          margin: 60px auto 20px;
          a {
            &:before {
              content: '';
              background: url("../images/logo-copmed.svg") center no-repeat;
              display: block;
              width: 100%;
              height: 50px;
              background-size: contain;
              margin-bottom: 15px;
            }
            font-size: 13px;
            color: #424549;
            text-align: center;
            cursor: pointer;
            display: inline-block;
            text-decoration: none;
          }
        }
      }
    }
  }
  /* End Home */

  /* Page */
  &-page {
    .pageLayout__header {
      height: 85px;
      background: #fff;
      padding-bottom: 10px;
      position: relative;
      z-index: 3;
      &__container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        padding: 0 20px;
      }
      &__logo {
        height: 100%;
        img {
          height: 70px;
          position: relative;
          top: 50%;
          @include transform(translateY(-50%));
        }
      }
      &__menu {
        position: absolute;
        width: 100%;
        left: 0;
        top: 85px;
        &__links {
          display: none;
          background: #fff;
          z-index: 10;
          position: relative;
          ul {
            width: 100%;
          }
          &__link {
            padding: 10px 15px;
            border-top: 1px solid;
            border-color: #e2e2e2;
            a {
              color: $bleu;
              font-size: 15px;
              font-weight: 700;
              letter-spacing: 0px;
              line-height: 24px;
              text-transform: uppercase;
              text-decoration: none;
              display: block;
              @include transition(all .2s ease);
              &:hover, &.active {
                color: $linkBleuHover;
              }
              span {
                display: inline-block;
                width: 100%;
              }
            }
          }
        }
      }
      &__mobile {
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        &__container {
          line-height: 0;
          height: 55%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          margin: 0 auto;
          cursor: pointer;
        }
        &__lines {
          background: #000;
          display: inline-block;
          width: 40px;
          height: 3px;
          background-clip: padding-box;
          position: relative;
          &.open {
            @include transition(background .2s 0s ease-in-out);
            background: transparent;

            &:after,
            &:before {
              width: 40px;
              top: 0;
              @include transition(top .2s ease-in-out,-webkit-transform .3s .4s ease-in-out,-moz-transform .3s .4s ease-in-out,-o-transform .3s .4s ease-in-out,transform .3s .4s ease-in-out);
            }
            &:after {
              @include transform(rotate3d(0,0,1,-45deg));
            }
            &:before {
              @include transform(rotate3d(0,0,1,45deg));
            }
          }
          &:after,
          &:before {
            content: '';
            display: inline-block;
            background: #000;
            width: 40px;
            height: 3px;
            background-clip: padding-box;
            position: absolute;
            left: 0px;
            @include css3-prefix(transform-origin, 50% 50%);
            @include transition(top .2s ease-in-out 0.3s, -webkit-transform .2s ease-in-out 0s, -moz-transform .2s ease-in-out 0s, -o-transform .2s ease-in-out 0s, transform .2s ease-in-out 0s);
          }
          &:after {
            top: -12px;
          }
          &:before {
            top: 12px;
          }
        }
      }
    }

    .pageLayout__content {
      &__wrapper {
        margin-top: 40px;

        h1 {
          font-size: 19px;
          color: $bleu;
          font-weight: 500;
          line-height: 24px;
        }

        h3 {
          margin-top: 20px;
          font-weight: 500;
          color: #939393;
          font-size: 14px;
          line-height: 20px;
          span {
            color: $bleu;
          }
        }
      }

      &__bg {
        padding: 0 20px;
        position: relative;
        height: 340px;
        margin: 0 0 20px 0;
        &:before {
          content: '';
          position: absolute;
          height: calc(100% - 40px);
          display: block;
          width: 100%;
          right: 0;
          top: 50%;
          @include transform(translateY(-50%));
          z-index: -1;
        }
        &:after {
          content: '';
          border: 1px solid #007582;
          width: 100%;
          height: 100%;
          display: block;
          z-index: 2;
        }
      }
      &__description {
        text-align: center;
        font-weight: 400;
        padding: 0 0 30px;
        position: relative;
        z-index: 2;
        h1 {
          color: $bleu;
          font-weight: 600;
          font-size: 40px;
          line-height: 45px;
          margin: 38px 0 33px 0;
        }
        h4 {
          color: #000;
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          margin: 10px 0 6px 0;
        }
        p {
          color: $gris;
          font-size: 16px;
          line-height: 26px;
        }
        &__bloc {
          margin-bottom: 20px;
          p {
            font-size: 14px;
            line-height: 18px;
            margin: 6px 0;
            color: $gris;
            font-weight: 500;
            span {
              color: $bleu;
            }
          }
          ul {
            li {
              font-weight: 500;
            }
          }
        }
      }
      &__fleur {
        padding: 0 0 50px;
        &__title {
          h1 {
            color: $bleu;
            font-weight: 600;
            font-size: 30px;
            line-height: 34px;
            margin: 38px 0 33px 0;
          }
        }
        &__content {
          z-index: 2;
          position: relative;
        }
        h4 {
          color: #000;
          font-weight: 600;
          font-size: 20px;
          line-height: 25px;
          margin: 10px 0 6px 0;
        }
        p {
          font-size: 14px;
          line-height: 18px;
          margin: 6px 0;
          color: $gris;
          span {
            color: $bleu;
          }
        }
        &__form {
          .field {
            padding: 15px 0 20px;
            &.has-error {
              label {
                color: $rouge;
              }
              .help-block {
                color: $rouge;
              }
              input, select, textarea {
                color: $rouge;
              }
              .error {
                background: rgb(251, 187, 199);
                font-size: 14px;
                color: #000;
                padding: 7px 10px;
                margin-top: 10px;
              }
            }
            label {
              color: $gris;
              font-size: 13px;
              line-height: 18px;
              font-weight: 400;
              display: block;
              width: 100%;
              text-align: left;
              margin-bottom: 15px;
            }
            select {
              color: $bleu;
              background-color: #ffffff;
              background: url("../images/fleche-select-bleu.svg") no-repeat #fff;
              background-position: center right 20px;
              @include transition(all .2s ease);
              padding: 10px 8px;
              height: 38px;
              -moz-appearance: none;
              -webkit-appearance: none;
              appearance: none;
              &:focus {
                background-color: $backgroundBleu;
                background: url("../images/fleche-select-blanc.svg") no-repeat $backgroundBleu;
                background-position: center right 20px;
                color: #fff;
              }
            }
            input, textarea {
              padding: 10px 8px;
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
            }
            input, select, textarea {
              @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, 0.1));
              @include border-radius(0px);
              border: none;
              width: 100%;
              outline: none;
              font-size: 13px;
            }
            input {
              border: 1px solid #eeeeee;
            }
            textarea {
              min-height: 100px;
              max-height: 150px;
              max-width: 100%;
              min-width: 100%;
              border: 1px solid #eeeeee;
            }
            .help-block {
              color: #939393;
              font-size: 10px;
              margin: 16px 0 0;
              font-style: italic;
            }

            .content-field-checkbox {
              margin: 10px 0 10px;
              .checkbox {
                input {
                  display: none;
                }
                label {
                  color: $bleu;
                  text-align: center;
                  font-size: 14px;
                  line-height: 18px;
                  @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, 0.1));
                  background-color: #fff;
                  padding: 10px 15px 15px;
                  box-sizing: border-box;
                  margin: 15px 0;
                  font-weight: 500;
                  display: inline-block;
                  width:100%;
                  @include transition(all .2s ease);
                  &.active {
                    background-color: $backgroundBleu;
                    color: #fff;
                  }
                }
              }
            }
          }
          &__buttons {
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              margin: 30px 0 0;
          }
        }
      }
    }

    .pageLayout__footer {
      padding: 15px 0;
      background-image: linear-gradient(to left, #96dbde, #439296);
      &__container {
        padding: 0 20px;
      }
      &__menu {
        ul {
          li {
            margin: 8px;
            text-align: center;
            &:first-child {
              margin-left: 0;
              margin-top: 0;
            }
            &:last-child {
              margin-right: 0;
            }
            a {
              color: #fff;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
  /* End Page */

  /* CMS */
  &-cms {
    .pageLayout__content {
      &__description {
        margin: 30px 0 0;
        text-align: left;
        h3 {
          margin: 9px 0;
        }
        ul {
          list-style: disc;
          font-size: 14px;
          line-height: 18px;
          color: $gris;
          padding-left: 30px;
          li {
            margin: 4px 0;
          }
        }
        p {
          font-size: 14px;
          line-height: 18px;
          margin: 6px 0;
          span {
            color: $bleu;
          }
        }
      }
    }
  }
  /* End CMS */

  /* Consent */
  &-consent {
    .pageLayout__content {
      &__description {
        form {
          padding: 50px 0 30px;
          .field {
            text-align: left;
            display: flex;
            flex-wrap: wrap;
            &-error {
              margin-bottom: 15px;
              p {
                color: $rouge;
                font-size: 16px;
              }
            }
            input {
              height: 14px;
              width: 14px;
            }
            label {
              color: $gris;
              font-size: 16px;
              font-weight: 400;
              margin-left: 20px;
            }
          }
          button {
            width: 100%;
            margin-top: 50px;
          }
        }
        .mention-small {
          font-style: italic;
          margin-top: 20px;
          font-size: 15px;
          color:#959595;
        }
      }
    }
  }
  /* End Consent */

  /* Form */
  &-form {
    .pageLayout__content {
      &__head {
        margin: 50px 0 25px;
        img {
          max-width: 350px;
        }
      }
      &__progress {
        p {
          color: $bleu;
          text-align: center;
          font-size: 15px;
          font-weight: 400;
          margin-bottom: 8px;
        }
        .progressbar {
          width: 100%;
          margin-top: 5px;
          margin-bottom: 35px;
          position: relative;
          background-color: #fff;
          border: 1px solid #d9d9d9;
          .progress {
            height: 8px;
            width: 10px;
            background-color: #3498db;
          }
        }
      }
      &__stepform {
        margin-top: 40px;
        position: relative;
        z-index: 1;

        &__title {
          padding: 15px 8px 21px;
          background: #fff;
          @include box-shadow(4px, 7px, 10px, rgba(126, 176, 181, 0.29));
          max-width: 280px;
          margin: 0 auto;
          h1 {
            font-weight: 500;
            text-align: center;
            font-size: 29px;
            line-height: 34px;
            color: $bleu;
          }
        }

        &__content {
          margin-top: 20px;
          position: relative;
          padding: 40px 4px 0;
          overflow: hidden;
          &:first-child {
            margin-top: 50px;
            padding-top: 0;
            &:before {
              display: none;
            }
          }

          &:before {
            content: '';
            height: 1px;
            background: #d9d9d9;
            width: 100%;
            display: block;
            top: 0;
            position: absolute;
          }

          &__title {
            margin-bottom: 10px;
            h2 {
              font-weight: 500;
              color: $bleu;
              margin-bottom:7px;
            }
            span {
              font-size: 11px;
              line-height: 16px;
              color: #939393;
              display: block;
              strong {
                color: $bleu;
              }
            }
          }
          &__fields {
            .field {
              padding: 15px 0 20px;
              &.has-error {
                label {
                  color: $rouge;
                }
                .help-block {
                  color: $rouge;
                }
                input, select, textarea {
                  color: $rouge;
                }
                .error {
                  background: rgb(251, 187, 199);
                  font-size: 14px;
                  color: #000;
                  padding: 7px 10px;
                  margin-top: 10px;
                }
              }
              label {
                color: $gris;
                font-size: 14px;
                line-height: 18px;
                font-weight: 400;
                display: block;
                width: 100%;
                text-align: left;
                margin-bottom: 15px;
                &.required {
                  //.required {
                  //  background: url(../images/required.svg) top center no-repeat;
                  //  background-size: 7px 7px;
                  //  display: inline-block;
                  //  width: 7px;
                  //  height: 10px;
                  //  margin-left: 4px;
                  //}
                }
              }
              select {
                color: $bleu;
                background-color: #ffffff;
                background: url("../images/fleche-select-bleu.svg") no-repeat #fff;
                background-position: center right 20px;
                @include transition(all .2s ease);
                padding: 10px 8px;
                height: 38px;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                &:focus {
                  background-color: $backgroundBleu;
                  background: url("../images/fleche-select-blanc.svg") no-repeat $backgroundBleu;
                  background-position: center right 20px;
                  color: #fff;
                }
              }
              input, textarea {
                padding: 10px 8px;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
              }
              input, select, textarea {
                @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, 0.1));
                @include border-radius(0px);
                border: none;
                width: 100%;
                outline: none;
                font-size: 13px;
              }
              input {
                border: 1px solid #eeeeee;
              }
              textarea {
                min-height: 100px;
                max-height: 150px;
                border: 1px solid #eeeeee;
              }
              .help-block {
                color: #939393;
                font-size: 10px;
                margin: 16px 0 0;
                font-style: italic;
              }

              .content-field-checkbox {
                margin: 10px 0 10px;
                .checkbox {
                  input {
                    display: none;
                  }
                  label {
                    color: $bleu;
                    text-align: center;
                    font-size: 15px;
                    line-height: 18px;
                    @include box-shadow(0, 5px, 10px, rgba(0, 0, 0, 0.1));
                    background-color: #fff;
                    padding: 10px 15px 15px;
                    box-sizing: border-box;
                    margin: 15px 0;
                    font-weight: 500;
                    display: inline-block;
                    width:100%;
                    @include transition(all .2s ease);
                    &.active {
                      background-color: $backgroundBleu;
                      color: #fff;
                    }
                  }
                }
              }
            }
          }
        }

        &__buttons {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 30px 0 40px;
          a {
            text-decoration: none;
            box-sizing: border-box;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              text-decoration: none;
            }
          }
          button, a {
            font-family: 'Montserrat', sans-serif;
            font-weight: 500;
            font-size: 13px;
            max-width: 50%;
          }
        }
      }
    }
  }
  /* End Form */
}

@media screen and (min-width: 370px) {
  .pageLayout {
    /* Form */
    &-form {
      .pageLayout__content {
        &__stepform {
          &__buttons {
            button, a {
              min-width: 160px;
            }
          }
        }
      }
    }
    /* End Form */
  }
}

@media screen and (min-width: 500px) {
  /* WEBP */
  .webp {
    .pageLayout {
      /* Home */
      &-home {
        .pageLayout__content {
          &__media {
            &:before {
              background: url("../images/bg-bionalysx768.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Home */

      /* Consent */
      &-consent {
        .pageLayout__content {
          &__bg {
            &:before {
              content: '';
              background: url("../images/consentementx768.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: bottom center;
            }
          }
        }
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        .pageLayout__content {
          &__bg {
            &:before {
              content: '';
              background: url("../images/bilanx768.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Bilan */
    }
  }
  /* END WEBP */

  /* NO WEBP */
  .no-webp,
  .no-js {
    .pageLayout {
      /* Home */
      &-home {
        .pageLayout__content {
          &__media {
            &:before {
              background: url("../images/bg-bionalysx768.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Home */

      /* Consent */
      &-consent {
        .pageLayout__content {
          &__bg {
            &:before {
              content: '';
              background: url("../images/consentementx768.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: bottom center;
            }
          }
        }
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        .pageLayout__content {
          &__bg {
            &:before {
              content: '';
              background: url("../images/bilanx768.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Bilan */
    }
  }
  /* END NO WEBP */
}

@media screen and (min-width: 768px) {
  .btn {
    padding: 18px;
    font-size: 26px;
    &.btn-bleu {
    }
  }

  /* WEBP */
  .webp {
    .pageLayout {
      /* Home */
      &-home {
        .pageLayout__content {
          &__media {
            &:before {
              background: url("../images/bg-bionalysx1024.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Home */

      /* Consent */
      &-consent {
        .pageLayout__content {
          &__bg {
            &:before {
              content: '';
              background: url("../images/consentementx1024.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: bottom center;
            }
          }
        }
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        .pageLayout__content {
          &__bg {
            &:before {
              content: '';
              background: url("../images/bilanx1024.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Bilan */
    }
  }
  /* END WEBP */

  /* NO WEBP */
  .no-webp,
  .no-js {
    .pageLayout {
      /* Home */
      &-home {
        .pageLayout__content {
          &__media {
            &:before {
              background: url("../images/bg-bionalysx1024.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Home */

      /* Consent */
      &-consent {
        .pageLayout__content {
          &__bg {
            &:before {
              content: '';
              background: url("../images/consentementx1024.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: bottom center;
            }
          }
        }
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        .pageLayout__content {
          &__bg {
            &:before {
              content: '';
              background: url("../images/bilanx1024.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Bilan */
    }
  }
  /* END NO WEBP */

  /* Desktop */
  .isDesktop {
    .pageLayout {
      &-form {
        .pageLayout__content {
          &__stepform__content__fields {
            .field {
              .content-field-radio {
                margin: 0 0 10px;
                display: flex;
                flex-wrap: wrap;
                label {
                  cursor: pointer;
                  width: auto;
                  padding: 10px 30px;
                  margin: 10px 30px 10px 0;
                }
              }
              input {
                border: none;
              }
            }
          }
        }
      }
    }
  }
  /* END Desktop */

  .pageLayout {
    /* Home */
    &-home {
      .pageLayout__content {
        &__media {
          height: 338px;
        }
        &__account {
          h2 {
            font-size: 24px;
            line-height: 28px;
          }
          &__content {
            &__accounts {
              display: flex;
              align-items: flex-start;
              justify-content: center;
              text-align: left;
              a {
                padding: 8px;
              }
              &__create {
                margin: 0 0 0 20px;
                width: 42%;
              }
              &__already {
                margin: 0 20px 0 0;
                width: 42%;
              }
            }
          }
        }
        &__process {
          max-width: 600px;
          display: flex;
          flex-wrap: wrap;
          margin: 65px auto 0;
          justify-content: space-between;
          &:after {
            max-width: 600px;
          }
          &__item {
            margin: 0 0 40px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
        &__customer {
          display: flex;
          justify-content: flex-end;
          &:before {
            content: '';
            background: url("../images/fleurs-bg.svg") no-repeat;
            background-size: contain;
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 34%;
            height: 270px;
          }
          &__container {
            width: 64%;
          }
          &__copmed {
            a {
              &:before {
                background-position: left;
              }
              text-align: left;
            }
          }
        }
      }
    }
    /* End Home */

    /* Page */
    &-page {
      .pageLayout__content {
        &__bg {
          height: 400px;
        }
        &__wrapper {
          h1 {
            max-width: 95%;
            line-height: 28px;
            font-size: 22px;
          }
          h3 {
            max-width: 95%;
          }
        }
        &__fleur {
          min-height: 280px;
          display: flex;
          justify-content: flex-end;
          &:after {
            content: '';
            background: url("../images/fleurs-bg.svg") no-repeat;
            background-size: contain;
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 34%;
            height: 270px;
          }
          &__title {
            h1 {
              font-size: 40px;
              line-height: 44px;
            }
          }
          &__content {
            width: 65%;
          }
        }
      }

      .pageLayout__footer {
        &__menu {
          ul {
            display: flex;
            justify-content: center;
            li {
              margin: 0 8px;
              text-align: left;
            }
          }
        }
      }
    }
    /* End Page */

    /* Form */
    &-form {
      .pageLayout__content {
        &__stepform {
          form {
            min-height: 500px;
          }
          &__content {
            &__fields {
              .field {
                .content-field-checkbox {
                  margin: 0 0 10px;
                  display: flex;
                  flex-wrap: wrap;

                  .checkbox {
                    label {
                      cursor: pointer;
                      width: auto;
                      padding: 10px 30px;
                      margin: 10px 30px 10px 0;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    /* End Form */
  }
}

@media screen and (min-width: 1024px) {
  /* WEBP */
  .webp {
    .pageLayout {
      /* Home */
      &-home {
        .pageLayout__content {
          &__media {
            &:before {
              background: url("../images/bg-bionalysx1200.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Home */

      /* Consent */
      &-consent {
        background: url("../images/consentementx1200.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-attachment: fixed;
        .pageLayout__content {
          &__bg {
            &:before {
              background: unset;
            }
          }
        }
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        background: url("../images/bilanx1200.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-attachment: fixed;
        .pageLayout__content {
          &__bg {
            &:before {
              background: unset;
            }
          }
        }
      }
      /* End Bilan */
    }
  }
  /* END WEBP */

  /* NO WEBP */
  .no-webp,
  .no-js {
    .pageLayout {
      /* Home */
      &-home {
        .pageLayout__content {
          &__media {
            &:before {
              background: url("../images/bg-bionalysx1200.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Home */

      /* Consent */
      &-consent {
        background: url("../images/consentementx1200.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-attachment: fixed;
        .pageLayout__content {
          &__bg {
            &:before {
              background: unset;
            }
          }
        }
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        background: url("../images/bilanx1200.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-attachment: fixed;
        .pageLayout__content {
          &__bg {
            &:before {
              background: unset;
            }
          }
        }
      }
      /* End Bilan */
    }
  }
  /* END NO WEBP */

  .pageLayout {
    &__content {
      margin: 0 34px;
      position: relative;
      &-middle {
        padding: 38px;
        @include box-shadow(5px, 5px, 10px, rgba(0, 0, 0, 0.16));
        background-color: rgba(255, 255, 255, 0.9);
        max-width: 900px;
        margin: 0 auto;
        .pageLayout__content__container {
          padding: 0 116px;
        }
        .pageLayout__content__head {
          img {
            max-width: 500px;
          }
        }

        &:before {
          content: '';
          position: absolute;
          border: 2px solid $bleu;
          width: calc(100% - 76px);
          height: calc(100% - 76px);
          display: block;
          top: 50%;
          left: 50%;
          @include transform(translate(-50%, -50%));
          z-index: 1
        }
      }

      .message-top {
        font-size: 16px;
        margin: 0 0 30px;
      }
    }

    /* Home */
    &-home {
      padding: 0;
      .pageLayout__content {
        &__container {
          padding: 0;
        }
        &:before {
          display: none;
        }
        &__account {
          position: absolute;
          top: 0;
          z-index: 1;
          @include box-shadow(5px, 5px, 10px, rgba(0, 0, 0, 0.16));
          background-color: rgba(255, 255, 255, 0.9);
          width: 70%;
          top: 50%;
          @include transform(translateY(-50%));
          left: 30px;
          h2 {
            text-align: left;
            font-size: 28px;
            line-height: 32px;
          }
          &__content {
            &__praticien {
              text-align: left;
              font-size: 22px;
              line-height: 26px;
            }
            &__accounts {
              justify-content: space-between;
            }
          }
        }
        &__head {
          position: relative;
          &:after {
            display: none;
          }
        }
        &__media {
          height: 396px;
          &:before {
            top: 0;
            height: 100%;
            transform: unset;
          }
          &:after {
            border: none;
            display: none;
          }
        }
        &__process {
          &:after {
            margin: -20px auto 0;
          }
        }
        &__customer {
          &__title {
            text-align: left;
            font-size: 26px;
            line-height: 30px;
          }
        }
      }
    }
    /* End Home */

    /* Page */
    &-page {
      //background-image: linear-gradient(to left, #96dbde, #439296);
      //background-color: #439296;
      .pageLayout__header {
        padding: 10px 0;
        &__container {
          padding: 0 34px;
        }
        &__mobile {
          display: none;
        }
        &__logo {
          img {
            height: 85px;
          }
        }
        &__menu {
          position: relative;
          width: 100%;
          left: 0;
          top: 0;
          &__container {
            height: 100%;
          }
          &__links {
            display: flex!important;
            align-items: center;
            justify-content: flex-end;
            height: 100%!important;
            ul {
              display: flex;
              align-items: center;
              width: auto;
            }
            &__link {
              border: 0;
              padding: 0 10px;
              &:last-child {
                padding-right: 0;
              }
              a {
                font-weight: 500;
                text-transform: initial;
              }
            }
          }
        }
      }

      .pageLayout__content {
        &__bg {
          display: none;
        }
        &__wrapper {
          background-color: #fff;
          padding: 100px 80px 100px;
          position: relative;
          margin-top: 0;
          @include box-shadow(5px, 5px, 10px, rgba(0, 0, 0, 0.16));
          h1 {
            font-size: 26px;
            line-height: 30px;
          }
          &:before {
            content: '';
            position: absolute;
            border: 2px solid $bleu;
            width: calc(100% - 80px);
            height: calc(100% - 80px);
            display: block;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            z-index: 1
          }
        }
        &__fleur {
          min-height: 200px;
          &:after {
            width: 40%;
            height: 348px;
          }
          &__title {
            h1 {
              font-size: 40px;
              line-height: 44px;
            }
          }
          &__form {
            &__col12 {
              display: flex;
              justify-content: space-between;
              .field {
                width: 100%;
              }
            }
            &__col6 {
              width: 46%;
            }
          }
        }
        &__description {
          margin-bottom: 68px;
          h1 {
            font-size: 50px;
            margin: 60px 0 35px 0;
          }
          h3 {
            margin-top: 24px;
            font-size: 18px;
            line-height: 22px;
          }
          p {
            font-size: 16px;
            line-height: 20px;
          }
          &__bloc {
            p {
              font-size: 14px;
              line-height: 18px;
            }
          }
          text-align: left;
        }
      }

      .pageLayout__footer {
        background: none;
        padding: 25px 0 60px;
        &__menu {
          ul {
            li {
              a {
                color: $bleu;
              }
            }
          }
        }
      }
    }
    /* End Page */

    /* Contact */
    &-contact {
      .pageLayout__content {
        &__fleur {
          padding: 0;
        }
      }
    }
    /* End Contact */

    /* Demandeaccesspro */
    &-demandeaccesspro {
      .pageLayout__content {
        &__fleur {
          padding: 0;
        }
      }
    }
    /* End Demandeaccesspro */

    /* CMS */
    &-cms {
      .pageLayout__content {
        &__description {
          margin-bottom: 0;
        }
      }
    }
    /* CMS */

    /* Bilan */
    &-bilan {
      min-height: 100%;
      padding: 34px 0;
    }
    /* End Bilan */

    /* Consent */
    &-consent {
      padding: 34px 0;
      .pageLayout__content {
        &__description {
          form {
            padding: 50px 0 0;
          }
        }
      }
    }
    /* End Consent */

    /* Form */
    &-form {
      object-fit: contain;
      background-image: linear-gradient(to left, #96dbde, #439296);
      background-attachment: fixed;
      min-height: 100%;
      padding: 34px 0;

      .pageLayout__content {
        background: #fff;
        @include box-shadow(5px, 5px, 10px, rgba(0, 0, 0, 0.16));
        &:after {
          content: '';
          background: url("../images/fleurs-bg.svg");
          background-size: cover;
          position: absolute;
          bottom: 0;
          left: 0;
          display: block;
          width: 400px;
          height: 400px;
        }

        &__head {
          margin: 20px 0 0;
          img {
            max-width: 100%;
          }
        }
        &__cols {
          display: flex;
          justify-content: space-between;
          &__left {
            width: 250px;
          }
          &__right {
            width: calc(100% - 290px);
          }
        }
        &__progress {
          margin-top: 65px;
          max-width: 98%;
          p {
            text-align: left;
            margin-bottom: 16px;
          }
          .progressbar {
            margin-bottom: 0;
          }
        }
        &__stepform {
          &__content {
            &:first-child {
              margin-top: 8px;
            }
            padding: 30px 0 0;
            margin-top: 12px;
            &__title {
              margin-bottom: 27px;
            }
            &__fields {
              .field {
                &:first-child {
                  padding-top: 0;
                }
                &:last-child {
                  padding-bottom: 0;
                }
              }
            }
          }
          &__buttons {
            button, a {
              min-width: 44%;
              font-size: 21px;
              line-height: 24px;
            }
          }
        }
      }
    }
    /* End Form */
  }
}

@media screen and (min-width: 1200px) {
  /* WEBP */
  .webp {
    .pageLayout {
      /* Home */
      &-home {
        .pageLayout__content {
          &__media {
            &:before {
              background: url("../images/bg-bionalysx1400.webp");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Home */

      /* Consent */
      &-consent {
        background: url("../images/consentementx1400.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-attachment: fixed;
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        background: url("../images/bilanx1400.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
      }
      /* End Bilan */
    }
  }
  /* END WEBP */

  /* NO WEBP */
  .no-webp,
  .no-js {
    .pageLayout {
      /* Home */
      &-home {
        .pageLayout__content {
          &__media {
            &:before {
              background: url("../images/bg-bionalysx1400.jpg");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center center;
            }
          }
        }
      }
      /* End Home */

      /* Consent */
      &-consent {
        background: url("../images/consentementx1400.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-attachment: fixed;
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        background: url("../images/bilanx1400.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
      }
      /* End Bilan */
    }
  }
  /* END NO WEBP */

  /* Desktop */
  .isDesktop {
    .pageLayout {
      &-form {
        .pageLayout__content {
          .pageLayout__content__stepform {
            &__title {
              @include transition(position .3s ease, width .2s ease);
              &-fixed {
                position: fixed;
                width: 100%;
                top: 0;
                @include transition(position .3s ease, width .2s ease);
              }
            }
          }
        }
      }
    }
  }
  /* END DESKTOP */

  .pageLayout {
    /* Home */
    &-home {
      padding: 0;

      .pageLayout__content {
        &__account {
          width: 700px;
          left: 150px;
        }
        &__process {
          max-width: 100%;
          width: 100%;
          &__item {
            width: 22%;
          }
          &:after {
            width: 100%;
            max-width: 100%;
          }
        }
        &__customer {
          &__container {
            width: 70%;
          }
          &:before {
            height: 415px;
          }
        }
      }
    }
    /* End Home */

    /* Page */
    &-page {
      .pageLayout__header {
        &__container {
          max-width: 1100px;
          margin: 0 auto;
        }
        &__menu {
          &__links {
            &__link {
              padding: 0 15px;
            }
          }
        }
      }

      .pageLayout__content,
      .pageLayout__footer .pageLayout__footer__container {
        max-width: 1400px;
        margin: 0 auto;
      }

      .pageLayout__content {
        &-middle {
          max-width: 900px;
          margin: 0 34px;
          padding: 34px 0;
        }
        &__wrapper {
          padding: 120px 150px 120px;
        }
        &__fleur {
          min-height: 230px;
          &:after {
            height: 415px;
          }
        }
      }
    }
    /* End Page */

    /* Form */
    &-form {
      .pageLayout__content {
        &__cols {
          &__left {
            width: 300px;
          }

          &__right {
            width: calc(100% - 360px);
          }
        }
      }
    }
    /* End Form */
  }
}

@media (min-width: 1400px) {
  /* WEBP */
  .webp {
    .pageLayout {
      /* Consent */
      &-consent {
        background: url("../images/consentementx1920.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-attachment: fixed;
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        background: url("../images/bilanx1920.webp");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
      }
      /* End Bilan */
    }
  }
  /* END WEBP */

  /* NO WEBP */
  .no-webp,
  .no-js {
    .pageLayout {
      /* Consent */
      &-consent {
        background: url("../images/consentementx1920.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom center;
        background-attachment: fixed;
      }
      /* End Consent */

      /* Bilan */
      &-bilan {
        background: url("../images/bilanx1920.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-attachment: fixed;
      }
      /* End Bilan */
    }
  }
  /* END NO WEBP */
}